<template lang="pug">
aside.own-production(:class="{'own-production_home': blockMod === 'home'}")
  .own-production__cont.cont
    h2.own-production__title.title {{ blockName }}
    ul.own-production__list
      li.own-production__item(v-for="(item, i) in lists[blockMod]", :key="i")
        span.own-production__headline {{ item.headline }}
        p.own-production__text {{ item.text }}
</template>

<script>
import '@/assets/styles/components/own-production.sass'

export default {
  name: 'OwnProduction',
  props: {
    blockName: String,
    blockMod: {
      type: String,
      default: 'home'
    }
  },
  data () {
    return {
      lists: {
        home: [
          {
            headline: 'Бельгийское качество',
            text: 'Мы производим наш шоколад по технологиям лучших бельгийских поваров'
          }, {
            headline: 'Натуральные ингредиенты',
            text: 'Используем только качественные натуральные ингредиенты в составе продукта'
          }, {
            headline: 'Уникальный рецепт',
            text: 'Используем только качественные натуральные ингредиенты в составе продукта'
          }
        ],
        franchise: [
          {
            headline: 'Дизайн-проект',
            text: '3D дизайн-проект и комплексное оснащение с необходимым оборудованием'
          }, {
            headline: 'Ассортимент',
            text: 'Большой выбор готовых шоколадных изделий и форм для изготовления на заказ'
          }, {
            headline: 'Помощь в поиске сотрудников',
            text: 'Содействие в поиске кандидатур сотрудников'
          }, {
            headline: 'Материалы для обучения',
            text: 'Программа обучения и подготовки сотрудников собственной разработки'
          }, {
            headline: 'Поддержка',
            text: 'Методическая и консультационная поддержка при запуске и дальнейншей работы музея'
          }
        ]
      }
    }
  }
}
</script>
