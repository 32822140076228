const cors = process.env.NODE_ENV === 'production' ? 'same-origin' : 'cors'
const credentials = process.env.NODE_ENV === 'production' ? 'same-origin' : 'include'
const headers = { 'Content-Type': 'application/json' }
if (process.env.NODE_ENV !== 'production') headers.Authorization = `basic ${Buffer.from('dev_choco_vbg_ru:9X1h4N2w').toString('base64')}`

export default {
  async get (url) {
    const res = await fetch(url, {
      method: 'GET',
      mode: cors,
      credentials,
      headers
    })
    if (res.ok) return await res.json()
    return null
  },
  async post (url, data) {
    const res = await fetch(url, {
      method: 'POST',
      mode: cors,
      credentials,
      headers,
      body: JSON.stringify(data)
    })
    if (res.ok) return await res.json()
    return null
  }
}
