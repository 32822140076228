<template lang='pug'>
.search
  .search__cont.cont
    form.search__form(@submit.prevent="sendRequest")
      .search__field
        input.search__input.text(type="search", placeholder="Найти…", v-model="searchQuery")
        button.search__submit(type="submit", :disabled = "!searchQuery || searchQuery.length < 3")
    h1.search__caption.caption {{searchTitle}}
    p.search__text.text-big(v-if="!searchInProgress" ) {{resultTextLine}}
</template>

<script>
import TileLine from '../_tiles/TileLine'
import { getDeclination } from '../../assets/scripts/getDeclination'

export default {
  name: 'TheSearch',
  components: { TileLine },
  props: {
    isShow: Boolean,
    response: Object,
    query: String,
    count: Number,
    searchInProgress: Boolean
  },
  data () {
    return {
      searchQuery: ''
    }
  },
  computed: {
    resultTextLine () {
      if (this.query) {
        if (this.count) {
          return `${getDeclination(this.count, ['Найден', 'Найдено', 'Найдено'])} ${this.count} ${getDeclination(this.count, ['результат', 'результата', 'результатов'])} по запросу «${this.query}»`
        } else {
          return `По вашему запросу «${this.query}» ничего не найдено`
        }
      } else {
        return 'Введите поисковый запрос'
      }
    },
    searchTitle () {
      return this.searchInProgress ? 'Поиск...' : 'Результаты поиска'
    }
  },
  methods: {
    sendRequest () {
      this.$emit('sendRequest', this.searchQuery)
    }
  }
}
</script>

<style lang='sass'>
@import 'src/assets/styles/components/search'
</style>
