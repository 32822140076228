<template lang="pug">
aside.big-choice
  .big-choice__cont.cont
    h2.big-choice__title.title {{title}}
    p.big-choice__text {{text}}
    router-link.thanks__button.link-more(:to="{ name: 'Catalog' }") Смотреть каталог
    picture.big-choice__picture(v-for="(picture, i) in pictures",
        :key="i",
        :class="`big-choice__picture_${i + 1}`",
        :style="isShowPictures ? {opacity: picture.opacity} : false"
      )
      source(:srcset="`${picture.path}_1240-1x.webp 1x, ${picture.path}_1240-2x.webp 2x`", media="(max-width: 1240px)", type="image/webp")
      source(:srcset="`${picture.path}_1240-1x.jpg 1x, ${picture.path}_1240-2x.jpg 2x`", media="(max-width: 1240px)")
      source(:srcset="`${picture.path}.webp 1x, ${picture.path}-1.5x.webp 1.5x`", type="image/webp")
      source(:srcset="`${picture.path}.jpg 1x, ${picture.path}-1.5x.jpg 1.5x`")
      img.big-choice__image(:src="`${picture.path}.jpg`", :alt="picture.alt", :title="picture.alt", loading="lazy", :width="picture.width", :height="picture.height")
</template>

<script>

export default {
  name: 'TheBigChoice',
  data () {
    return {
      title: 'Шоколадная продукция',
      text: 'Мы производим шоколад, конфеты, фигурки из шоколада, корпоративные и личные подарки',
      pictures: [
        { path: '/images/big-choice/big-choice-1', alt: 'Шоколад на любой вкус фото', opacity: '0.2', width: '600', height: '600' },
        { path: '/images/big-choice/big-choice-2', alt: 'Авторский шоколад фото', opacity: '1', width: '676', height: '540' },
        { path: '/images/big-choice/big-choice-3', alt: 'Новогодняя открытка из шоколада фото', opacity: '1', width: '376', height: '526' }
      ],
      isShowPictures: false
    }
  },
  methods: {
    checkScrollToBlock () {
      const currentPosition = this.$el.getBoundingClientRect().top - document.documentElement.clientHeight
      // const swipe = window.innerWidth > 1240 ? -400 : 300
      if (currentPosition < -400) {
        this.isShowPictures = true
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.checkScrollToBlock)
  },
  unmounted () {
    window.removeEventListener('scroll', this.checkScrollToBlock)
  }
}
</script>

<style lang="sass">
@import 'src/assets/styles/components/big-choice'
</style>
