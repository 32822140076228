<template lang="pug">
router-link.tile-item(:to="{ name: 'Product', params: { slug: slug } }", @mouseover.once="preload(slug)", :class="{ 'tile-item_inactive': !isActive }")
  span.tile-item__new(v-if="isNew") NEW
  picture.tile-item__picture
    template(v-if="image")
      source(:srcset="`${image.w158} 1x, ${image.w316} 2x`", type="image/webp", media="(max-width: 360px)")
      source(:srcset="`${image.w190} 1x, ${image.w380} 2x`", type="image/webp", media="(max-width: 520px)")
      source(:srcset="`${image.w148} 1x, ${image.w296} 2x`", type="image/webp", media="(max-width: 700px)")
      source(:srcset="`${image.w196} 1x, ${image.w392} 2x`", type="image/webp", media="(max-width: 950px)")
      source(:srcset="`${image.w264} 1x, ${image.w396} 1.5x`", type="image/webp", media="(max-width: 1240px)")
      source(:srcset="`${image.w376} 1x, ${image.w564} 1.5x`", type="image/webp")
      source(:srcset="`${image.j376} 1x, ${image.j564} 1.5x`")
      img.tile-item__image(:src="image.j376", :alt="name", :title="name", loading="lazy", width="376", height="376")
    img.tile-item__image(v-else, src="/images/logo.svg", :alt="name", :title="name", loading="lazy", width="376", height="376")
  .tile-item__price-box
    span.tile-item__actual-price(v-if="priceCurrent") {{ priceCurrent.toLocaleString() }}
    span.tile-item__old-price(v-if="priceOld") {{ priceOld.toLocaleString() }}
  .tile-item__note
    span.tile-item__name {{ name }}
    span.tile-item__value(v-if="value") {{ value }} г.
  .tile-item__button-box(v-if="$route.name === 'Favorites'")
    button.tile-item__in-cart.button-white(type="button", @click.prevent="addProduct", :class="{'tile-item__in-cart_added': isAdded}") {{ isAdd }}
    button.tile-item__favorites(type="button", @click.prevent="isActive = !isActive", @click="toggleFavorites", :class="{ 'tile-item__favorites_active': isActive }")
    button.tile-item__toggle-favorites.text(type="button", @click.prevent="isActive = !isActive", @click="toggleFavorites") {{mobileButtonText}}
</template>

<script>
import '@/assets/styles/components/tile-item.sass'
import {
  addProduct,
  getProduct,
  refreshUserLazy,
  addInFavorites,
  removeFromFavorites,
  refreshProduct
} from '../../assets/scripts/API'

export default {
  props: {
    slug: { type: String, required: true },
    isNew: { type: [Number, Boolean] },
    image: { type: Object },
    priceCurrent: { type: Number, required: true },
    priceOld: { type: Number },
    name: { type: String, required: true },
    value: { type: Number },
    id: { type: Number },
    productsInBasket: { type: Array }
  },
  name: 'TileItem',
  data () {
    return {
      isActive: true,
      isAdded: false,
      isFavoritesPage: false,
      isInFavorites: true
    }
  },
  computed: {
    isAdd () {
      return this.isAdded ? 'В корзине' : 'В корзину'
    },
    mobileButtonText () {
      return this.isActive ? 'Удалить' : 'Вернуть'
    }
  },
  methods: {
    preload (slug) {
      if (window.innerWidth > 1240) getProduct(slug)
    },
    async addProduct () {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 500)
      const product = this.productsInBasket.find(item => item.id === this.id)
      if (product) {
        await addProduct(this.id, product.quantity + 1)
      } else {
        await addProduct(this.id, 1)
      }
      this.isAdded = true
      refreshUserLazy()
    },
    async toggleFavorites () {
      if (this.isInFavorites) {
        await removeFromFavorites(this.id)
      } else {
        await addInFavorites(this.id)
      }
      this.isInFavorites = !this.isInFavorites
      refreshProduct(this.slug)
    }
  }
}
</script>
