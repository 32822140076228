<template lang="pug">
article.tile-line(v-if="products.length", :class="classMod ? `tile-line_${classMod}` : false")
  .tile-line__cont.cont
    h1.tile-line__title.title(v-if="caption")
      router-link.tile-line__title-link(v-if="route", :to="route") {{ caption }}
      template(v-else) {{ caption }}
    h2.tile-line__title.title(v-if="title")
      router-link.tile-line__title-link(v-if="route", :to="route") {{ title }}
      template(v-else) {{ title }}
    .tile-line__list(v-if="products.length")
      tile-item(
          v-for="product in products",
          :key="product.id",
          :slug="product.slug",
          :is-new="product.is_new",
          :image="product.image",
          :name="product.name",
          :value="product.value",
          :price-current="product.price_current",
          :price-old="product.price_old",
          :id="product.id",
          :cart-count = "product.cart_count"
        )
    router-link.tile-line__link.link-more(:to="route" v-if="route") Все
</template>

<script>
import '@/assets/styles/components/tile-line.sass'
import TileItem from './TileItem'

export default {
  props: {
    caption: String,
    title: String,
    description: String,
    route: Object,
    classMod: String,
    products: {
      type: Array,
      default () {
        return []
      }
    },
    productsInBasket: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: { TileItem },
  name: 'TileLine'
}
</script>
