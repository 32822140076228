import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue-v3-yandex-metrika'

createApp(App)
  .use(router)
  .use(VueYandexMetrika, {
    id: 57057949,
    router: router,
    env: process.env.NODE_ENV
  })
  .mount('#app')
