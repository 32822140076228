<template lang="pug">
teleport(to='head')
  title {{ titlePage }}
  template(v-if="description")
    meta(name="description", :content="description")
    meta(property='og:description', :content="description")
  meta(property="og:title", :content="titlePage")
  meta(property="og:type", content="website")
  meta(property="og:locale", content="ru_RU")
  meta(property="og:url", :content="`${url}${this.$route.path}`")
  meta(property="og:image", :content="imageSrc")
  meta(property="og:site_name", :content="domain")
</template>

<script>
import { url, domain } from '../../assets/scripts/variables'

export default {
  name: 'HeadMeta',
  props: {
    titlePage: {
      type: String,
      required: true
    },
    description: String,
    imageSrc: {
      type: String,
      default: `${url}/images/open-graph/open-graph_1200.jpg`
    }
  },
  data () {
    return {
      url,
      domain
    }
  }
}
</script>
