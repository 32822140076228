<template lang="pug">
head-meta(
  description="Это уникальное место, соединившее в себе музей с экспонатами выборгских достопримечательностей и магазин шоколада с собственным производством!",
  title-page="Музей «Шоколадный Выборг»"
)
the-home-cap
tile-line(:products="mainCategoryData.products", :title="mainCategoryData.name", :route="{ name: 'Category', params: { slug: mainCategoryData.slug } }")
tile-line(:products="popularData.products", caption="Магазин и Музей «Шоколадный Выборг»", :route="{ name: 'Catalog' }")
the-big-choice
tile-line(:products="newData.products", title="Новинки", :route="{ name: 'Catalog' }")
the-index-nav
the-postcards
own-production(block-name="Собственное производство", block-mod="home")
informer-order
index-slide(title="Фотографии экстерьера, интерьера и экспонатов", class-mod="home")
home-video
contacts-line
</template>

<script>
import TheHomeCap from '../components/home/TheHomeCap'
import TileLine from '../components/_tiles/TileLine'
import TheBigChoice from '../components/home/TheBigChoice'
import OwnProduction from '../components/OwnProduction'
import InformerOrder from '../components/_informers/InformerOrder'
import HomeVideo from '../components/HomeVideo'
import IndexSlide from '../components/IndexSlide'
import {
  getMainCategoryProductsForTheHome,
  getNewProductsForTheHome,
  getPopularProductsForTheHome
} from '../assets/scripts/API'
import TheIndexNav from '../components/index-nav/TheIndexNav'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'
import ThePostcards from '../components/home/ThePostcards'

export default {
  components: { ThePostcards, ContactsLine, HeadMeta, TheIndexNav, IndexSlide, TheBigChoice, HomeVideo, InformerOrder, TheHomeCap, TileLine, OwnProduction },
  name: 'HomePage',
  data () {
    return {
      popularData: [],
      newData: [],
      mainCategoryData: {}
    }
  },
  mounted () {
    getPopularProductsForTheHome().then(data => {
      this.popularData = data
    })
    getNewProductsForTheHome().then(data => {
      this.newData = data
    })
    getMainCategoryProductsForTheHome().then(data => {
      this.mainCategoryData = data
    })
  }
}
</script>
