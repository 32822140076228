<template lang="pug">
.home-video(:class="classMod ? `home-video_${classMod}`: false")
  .home-video__cont.cont
    .home-video__video(v-for="(video, i) in videos", :key="i")
      iframe.home-video__frame(
        :src="video.src",
        frameborder='0',
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
        loading='lazy'
      )
</template>

<script>
import '@/assets/styles/components/home-video.sass'

export default {
  name: 'HomeVideo',
  props: {
    classMod: String
  },
  data () {
    return {
      videos: [
        { src: 'https://www.youtube.com/embed/ddGXjCBXHVI' }
      ]
    }
  }
}
</script>
