<template lang="pug">
aside.informer-order
  .informer-order__cont.cont
    h2.informer-order__title.title Сделаем шоколад под заказ
    p.informer-order__text.text
      | Нe нашли что искали? Позвоните нам по&nbsp;номеру&nbsp;
      a.informer-order__link(:href="phone.link") {{phone.mask}}
      |  и закажите сладость своей мечты!
</template>

<script>
import '@/assets/styles/components/informer-order.sass'
import { phone } from '../../assets/scripts/variables'

export default {
  name: 'InformerOrder',
  data () {
    return {
      phone
    }
  }
}
</script>
