import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  }, {
    path: '/about',
    name: 'About',
    component: () => import('../views/AboutPage')
  }, {
    path: '/basket',
    name: 'Basket',
    component: () => import('../views/BasketPage')
  }, {
    path: '/birthdays',
    name: 'Birthdays',
    component: () => import('../views/BirthdaysPage')
  }, {
    path: '/user',
    // name: 'User',
    component: () => import('../views/UserPage'),
    children: [
      {
        path: '',
        name: 'UserInfo',
        component: () => import('../views/user/UserInfoPage')
      }, {
        path: 'orders',
        name: 'UserOrders',
        component: () => import('../views/user/UserOrdersPage')
      }, {
        path: 'logout',
        name: 'UserLogout',
        component: () => import('../views/user/UserLogoutPage')
      }
    ]
  }, {
    path: '/catalog',
    component: () => import('../views/CatalogPage'),
    children: [
      {
        path: '',
        name: 'Catalog',
        component: () => import('../views/catalog/CatalogIndexPage')
      }, {
        path: ':slug',
        name: 'Category',
        component: () => import('../views/catalog/CatalogCategoryPage')
      }
    ]
  }, {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/ContactsPage')
  }, {
    path: '/degustations',
    name: 'Degustations',
    component: () => import('../views/DegustationsPage')
  }, {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('../views/DeliveryPage')
  }, {
    path: '/excursion',
    name: 'Excursion',
    component: () => import('../views/ExcursionPage')
  }, {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../views/FavoritesPage')
  }, {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordPage')
  }, {
    path: '/franchise',
    name: 'Franchise',
    component: () => import('../views/FranchisePage')
  }, {
    path: '/grot',
    name: 'Grot',
    component: () => import('../views/GrotPage')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginPage')
  }, {
    path: '/master',
    name: 'Master',
    component: () => import('../views/MasterPage')
  }, {
    path: '/on-order',
    name: 'OnOrder',
    component: () => import('../views/OnOrderPage')
  }, {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/PaymentPage')
  }, {
    path: '/policy',
    name: 'Policy',
    component: () => import('../views/PolicyPage')
  }, {
    path: '/products/:slug',
    name: 'Product',
    component: () => import('../views/ProductPage')
  }, {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/RegistrationPage')
  }, {
    path: '/requisites',
    name: 'Requisites',
    component: () => import('../views/RequisitesPage')
  }, {
    path: '/search',
    name: 'Search',
    component: () => import('../views/SearchPage')
  }, {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/ThanksPage')
  }, {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('../views/VerifyEmailPage')
  }, {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: () => import('../views/ErrorPage')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
