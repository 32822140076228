<template lang='pug'>
article.index-nav
  .index-nav__cont.cont
      .index-nav__box(v-for="(item, i) in list", :key="i")
        picture.index-nav__picture
          source(:srcset="`${item.imageSrc}_328.webp 1x, ${item.imageSrc}_656.webp 2x`", type="image/webp", media="(max-width: 520px)")
          source(:srcset="`${item.imageSrc}_468.webp 1x, ${item.imageSrc}_936.webp 2x`", type="image/webp", media="(max-width: 700px)")
          source(:srcset="`${item.imageSrc}_347.webp 1x, ${item.imageSrc}_694.webp 2x`", type="image/webp", media="(max-width: 950px)")
          source(:srcset="`${item.imageSrc}_551.webp 1x, ${item.imageSrc}_1102.webp 2x`", type="image/webp", media="(max-width: 1240px)")
          source(:srcset="`${item.imageSrc}_776.webp 1x, ${item.imageSrc}_1164.webp 1.5x`", type="image/webp")
          source(:srcset="`${item.imageSrc}_328.jpg 1x, ${item.imageSrc}_656.jpg 2x`" media="(max-width: 520px)")
          source(:srcset="`${item.imageSrc}_468.jpg 1x, ${item.imageSrc}_936.jpg 2x`" media="(max-width: 700px)")
          source(:srcset="`${item.imageSrc}_347.jpg 1x, ${item.imageSrc}_694.jpg 2x`" media="(max-width: 950px)")
          source(:srcset="`${item.imageSrc}_551.jpg 1x, ${item.imageSrc}_1102.jpg 2x`" media="(max-width: 1240px)")
          img.index-nav__image(:src="`${item.imageSrc}_776.jpg`", :srcset="`${item.imageSrc}_1164.jpg 1.5x`", loading="lazy", :alt="item.imageAlt", :title="item.imageAlt", width="776", height="582")
        .index-nav__content
          h2.index-nav__title.index-nav__title_grot(v-if="item.pathName === 'Grot'") ГР
            picture.index-nav__title-picture
              source(srcset="/images/index-nav/index-nav-5_44.webp 1x, /images/index-nav/index-nav-5_88.webp 2x", type="image/webp",  media="(max-width: 1240px)")
              source(srcset="/images/index-nav/index-nav-5_82.webp 1x, /images/index-nav/index-nav-5_123.webp 1.5x", type="image/webp")
              source(srcset="/images/index-nav/index-nav-5_82.png 1x, /images/index-nav/index-nav-5_123.png 1.5x")
              img.index-nav__title-image(src="/images/index-nav/index-nav-5_82.png", alt="Грот", title="Грот", width="82", height="90")
            span.index-nav__title-letter T
          h2.index-nav__title.title(v-else) {{item.title}}
          p.index-nav__text(v-if="item.pathName === 'Grot'" v-html="item.text")
          p.index-nav__text(v-else) {{item.text}}
          router-link.index-nav__link.index-nav__link_more(v-if="item.pathName === 'Grot'", :to="{ name: item.pathName}" v-html="item.linkText" )
          router-link.index-nav__link.link-more(v-else, :to="{ name: item.pathName}") {{item.linkText}}
      .index-nav__box
        picture.index-nav__picture
          source(srcset="/images/index-nav/index-nav-4_328.webp 1x, /images/index-nav/index-nav-4_656.webp 2x", type="image/webp", media="(max-width: 520px)")
          source(srcset="/images/index-nav/index-nav-4_468.webp 1x, /images/index-nav/index-nav-4_936.webp 2x", type="image/webp", media="(max-width: 700px)")
          source(srcset="/images/index-nav/index-nav-4_347.webp 1x, /images/index-nav/index-nav-4_694.webp 2x", type="image/webp", media="(max-width: 950px)")
          source(srcset="/images/index-nav/index-nav-4_551.webp 1x, /images/index-nav/index-nav-4_1102.webp 2x", type="image/webp", media="(max-width: 1240px)")
          source(srcset="/images/index-nav/index-nav-4_776.webp 1x, /images/index-nav/index-nav-4_1164.webp 1.5x", type="image/webp")
          source(srcset="/images/index-nav/index-nav-4_328.jpg 1x, /images/index-nav/index-nav-4_656.jpg 2x", media="(max-width: 520px)")
          source(srcset="/images/index-nav/index-nav-4_468.jpg 1x, /images/index-nav/index-nav-4_936.jpg 2x" media="(max-width: 700px)")
          source(srcset="/images/index-nav/index-nav-4_347.jpg 1x, /images/index-nav/index-nav-4_694.jpg 2x" media="(max-width: 950px)")
          source(srcset="/images/index-nav/index-nav-4_551.jpg 1x, /images/index-nav/index-nav-4_1102.jpg 2x" media="(max-width: 1240px)")
          img.index-nav__image(src="/images/index-nav/index-nav-4_776.jpg", srcset="/images/index-nav/index-nav-4_1164.jpg 1.5x", loading="lazy", alt="Северный плацдарм - фото", title="Северный плацдарм - фото", width="776", height="582")
        .index-nav__content
          h2.index-nav__title.title Северный плацдарм
          p.index-nav__text  Уникальное пространство с тематическими аттракционами и активностями. Приходите и вдохновитесь эстетикой эпохи Петра I
          a.index-nav__link.link-more(href ="https://xn--80aaejcb2aqmexjhc8fsd.xn--p1ai/", target="_blank") Перейти на сайт парка
</template>

<script>
import '@/assets/styles/components/index-nav.sass'

export default {
  name: 'TheIndexNav',
  data () {
    return {
      list: [
        {
          imageSrc: 'images/index-nav/index-nav-2',
          imageAlt: 'Мастер-класс в Музее «Шоколадный Выборг»',
          title: 'Мастер-классы',
          text: 'Мастера шоколадного искусства проводят мастер-классы по программам для детей и взрослых',
          linkText: 'Узнать подробнее и записаться',
          pathName: 'Master'
        },
        {
          imageSrc: 'images/index-nav/index-nav-3',
          imageAlt: 'Вход в арт-пространство «Грот» - фото',
          title: 'Грот',
          text: '«В норе под землёй жил-был ХОББИТ»<br/>Вернее RABBIT<br/>КРОЛИК<br/>Он уже сварил для тебя ШОКОЛАДНОЕ ПИВО и пожарил сочный ВЫБУРГЕР<br/>ЗАХОДИ СКОРЕЕ!',
          linkText: 'Иди ЗА <span class="index-nav__line-through">Белым</span> <span class="index-nav__nowrap">ШОКОЛАДНЫМ КРОЛИКОМ</span>',
          pathName: 'Grot'
        }
      ]
    }
  }
}
</script>
