<template lang="pug">
the-header
.wrapper
  router-view
the-footer
</template>

<script>
import '@/assets/styles/tools/app.sass'
import '@/assets/styles/tools/buttons.sass'
import '@/assets/styles/tools/fonts.sass'
import '@/assets/styles/tools/framework.sass'
import '@/assets/styles/tools/inputs.sass'
import '@/assets/styles/tools/links.sass'
import TheHeader from './components/_layout/TheHeader'
import TheFooter from './components/_layout/TheFooter'

export default {
  name: 'App',
  components: { TheHeader, TheFooter }
}
</script>
