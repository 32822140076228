export const phone = {
  mask: '+7 (981) 847-99-23',
  link: 'tel:+79818479923'
}

export const mail = {
  mask: 'museumcv@yandex.ru',
  link: 'mailto:museumcv@yandex.ru'
}

export const franchisePhone = {
  mask: '+7 922 901-10-77',
  link: 'tel:+79229011077'
}

export const franchiseMail = {
  mask: 'museumcv@yandex.ru',
  link: 'mailto:museumcv@yandex.ru'
}

export const domain = 'choco-vbg.ru'
export const url = 'https://choco-vbg.ru'

export const address = 'Ул. Штурма, 1А, Выборг'
export const city = 'Выборг'
export const addressShort = 'ул. Штурма, 1А'
export const fullAddress = '188800, Ленинградская область, г. Выборг, ул. Штурма, Пороховой погреб, литера А'

export const worktime = {
  weekdays: 'Пн — Вс: с 10.00 до 20.00, без&nbsp;обеда'
}

export const minPriceForFreeDelivery = 3500

// export const worktime = {
//   weekdays: 'Пн — Чт: с 10.00 до 19.00',
//   weekends: 'Пт — Вс: с 10.00 до 20.00'
// }
