<template lang="pug">
.home-cap
  .home-cap__cont.cont
    img.home-cap__logo(src="/images/logo.svg", alt="Логотип Музея «Шоколадный Выборг»", title="Логотип Музея «Шоколадный Выборг»", width="480", height="480")
    p.home-cap__text.text-big Открой мир бельгийского <br/> шоколада ручной работы!
</template>

<script>
import '@/assets/styles/components/home-cap.sass'

export default {
  name: 'TheHomeCap'
}
</script>
