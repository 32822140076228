<template lang="pug">
article.index-slide(:class="classMod ? `index-slide_${classMod}`: false")
  .index-slide__cont.cont
    h2.index-slide__caption.title(v-if="title") {{title}}
    .index-slide__slider
      .index-slide__slider
      picture.index-slide__picture(v-for="(image, i) in visibleImages",
        :key="image",
        :class = "{'index-slide__picture_left-2': i === 0, 'index-slide__picture_left-1' : i === 1, 'index-slide__picture_center' : i === 2, 'index-slide__picture_right-1': i  === 3,  'index-slide__picture_right-2': i === 4}",
        @touchstart="setStartPositionTouchEvent", @touchmove="getDirectionTouchEvent", @touchend="moveSlide")
        source(:srcset='`${image.path}_288-191.webp 1x, ${image.path}_576-382.webp 2x`', type="image/webp", media="(max-width: 700px)")
        source(:srcset="`${image.path}_540-360.webp 1x, ${image.path}_1080-720.webp 2x`", type="image/webp", media="(max-width: 1240px)")
        source(:srcset="`${image.path}_776-516.webp`", type="image/webp")
        source(:srcset="`${image.path}_288-191.jpg 1x, ${image.path}_576-382.jpg 2x`", media="(max-width: 700px)")
        source(:srcset="`${image.path}_540-360.jpg 1x, ${image.path}_1080-720.jpg 2x`", media="(max-width: 1240px)")
        img.index-slide__image(:src="`${image.path}_776-516.jpg`", :alt="image.alt", :title="image.alt", loading="lazy", width="776", height="516")
      button.index-slide__button.index-slide__button_prev(aria-label="Предыдущий слайд", @click="moveToRight" )
      button.index-slide__button.index-slide__button_next(aria-label="Следующий слайд", @click="moveToLeft")
</template>

<script>
export default {
  name: 'IndexSlide',
  props: {
    title: String,
    classMod: String
  },
  data () {
    return {
      centerIndex: 0,
      visibleIndexes: [],
      visibleImages: [],
      images: [
        { path: '/images/index-slide/index-slide-11', alt: 'Музей «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-13', alt: 'Музей «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-14', alt: 'Корабль штандарт в Музее «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-1', alt: 'Интерьер Музея «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-3', alt: 'Экспонат Музея «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-4', alt: 'Шоколадный бутик в Выборге фото' },
        { path: '/images/index-slide/index-slide-5', alt: 'Модель Выборгского замка шоколад фото' },
        { path: '/images/index-slide/index-slide-6', alt: 'Модель Выборгской круглой башни шоколад фото' },
        { path: '/images/index-slide/index-slide-7', alt: 'Кафе Музея «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-8', alt: 'Интерьер кафе Музея «Шоколадный Выборг» фото' },
        { path: '/images/index-slide/index-slide-2', alt: 'Экспозиция Музея «Шоколадный Выборг» фото' }
      ],
      startPosition: 0,
      currentPosition: 0,
      direction: ''
    }
  },
  methods: {
    getVisibleIndexes (index) {
      const leftIndex = index === 0 ? this.images.length - 1 : index - 1
      const rightIndex = index === this.images.length - 1 ? 0 : index + 1
      const firstIndex = leftIndex === 0 ? this.images.length - 1 : leftIndex - 1
      const lastIndex = rightIndex === this.images.length - 1 ? 0 : rightIndex + 1
      this.visibleIndexes.push(firstIndex)
      this.visibleIndexes.push(leftIndex)
      this.visibleIndexes.push(index)
      this.visibleIndexes.push(rightIndex)
      this.visibleIndexes.push(lastIndex)
    },

    getVisibleImages (arrayIndexes) {
      for (let i = 0; i < arrayIndexes.length; i++) {
        const index = arrayIndexes[i]
        this.visibleImages.push(this.images[index])
      }
    },

    moveSlide () {
      this.addAnimationClass()
      setTimeout(() => {
        this.recalculateSlides()
      }, 400)
    },

    moveToLeft () {
      this.direction = 'left'
      this.moveSlide()
    },

    moveToRight () {
      this.direction = 'right'
      this.moveSlide()
    },

    recalculateSlides () {
      if (this.direction === 'left') {
        this.centerIndex = this.centerIndex === this.images.length - 1 ? 0 : this.centerIndex + 1
      } else {
        this.centerIndex = this.centerIndex === 0 ? this.images.length - 1 : this.centerIndex - 1
      }
      this.visibleIndexes = []
      this.getVisibleIndexes(this.centerIndex)
      this.visibleImages = []
      this.getVisibleImages(this.visibleIndexes)
    },

    addAnimationClass () {
      const slides = this.$el.querySelectorAll('picture')
      if (this.direction === 'left') {
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i]
          slide.classList.add('index-slide__picture_to-left')
        }
      } else {
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i]
          slide.classList.add('index-slide__picture_to-right')
        }
      }
    },
    removeAnimationClass () {
      const slides = this.$el.querySelectorAll('picture')
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i]
        slide.classList.remove('index-slide__picture_to-left')
        slide.classList.remove('index-slide__picture_to-right')
      }
    },

    setStartPositionTouchEvent (event) {
      this.startPosition = event.touches[0].clientX
    },

    getDirectionTouchEvent (event) {
      const currentPosition = event.touches[0].clientX
      if (this.startPosition > currentPosition) {
        this.direction = 'left'
      } else if (this.startPosition < currentPosition) {
        this.direction = 'right'
      }
    }
  },
  mounted () {
    this.getVisibleIndexes(this.centerIndex)
    this.getVisibleImages(this.visibleIndexes)
  }
}
</script>

<style lang="sass">
@import 'src/assets/styles/components/index-slide'
</style>
